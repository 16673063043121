<template>
  <div class="modal">
    <div class="brdr_r_12 bckg_white modal_block modal_max_width_640">
      <div class="modal_block_head">
        <div class="modal_block_head_title heading_2xl_h3">
          <slot name="head"></slot>
        </div>
        <div class="modal_block_head_item" v-if="!closed">
          <button @click="closeModal"  class="modal_block_head_item_button">
            <span v-html="CLOSE_ICON_14"></span>
            <p style="color: var(--blue);"
              class="medium_bold_m">{{$t('close')}}</p>
          </button>
        </div>
      </div>
      <div class="modal_block_body">
        <slot name="body"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { CLOSE_ICON_14 } from '@/store/icons'
export default {
  name: 'ModalComponent',
  props: {
    closed: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      CLOSE_ICON_14
    }
  },
  methods: {
    closeModal: function () {
      this.$emit('close-modal')
    }
  }
}
</script>
